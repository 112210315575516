import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useNavigate } from 'react-router-dom';
import {
  Button,
  Box,
  Flex,
  Heading,
  Stack,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast
} from '@chakra-ui/react';
import moment from 'moment';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [apiResponse, setApiResponse] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPersonifyModalOpen, setIsPersonifyModalOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [personifyEmail, setPersonifyEmail] = useState('');
  const [users, setUsers] = useState([]);
  const [paginationToken, setPaginationToken] = useState(null);
  const [prevPaginationToken, setPrevPaginationToken] = useState(null);
  const toast = useToast();

  useEffect(() => {
    checkAuthStatus();
    fetchUsers();
  }, []);

  const checkAuthStatus = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log('=====', user);
      const session = user.signInUserSession;
      const email = session.idToken.payload.email;
      setIsAuthenticated(true);
      setUserEmail(email);
    
    } catch (error) {
      console.log(error);
      setIsAuthenticated(false);
    }
  };
  

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      setIsAuthenticated(false);
    } catch (error) {
      console.log('Error signing out:', error);
    }
  };

  const handleSignIn = async () => {
    try {
      await Auth.federatedSignIn(); // Redirect to Cognito hosted UI
    } catch (error) {
      console.log('Error signing in:', error);
    }
  };

  const handleSubmitEmail = async () => {
    try {
      // Call your API with the POST method and provide the email
      const response = await fetch('https://hvbhn2kc13.execute-api.us-east-1.amazonaws.com/dev/emails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: emailInput }),
      });
      const data = await response.json();
      setApiResponse(data.message); // Assuming the API returns a message
      setIsModalOpen(true);
    } catch (error) {
      console.log('Error submitting email:', error);
    }
  };

  const handleInvalidate = async (env) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const email = user.signInUserSession.idToken.payload.email;
      if (email !== 'evan.moses@daffodilhealth.co' && email !== 'xicheng.chang@daffodilhealth.co') {
        toast({
          title: `Error Invalidate ${env}!`,
          description: 'Who are you? You are not Evan!', // Assuming the API returns a message
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      // Call your API with the POST method and provide the env
      const response = await fetch('https://hvbhn2kc13.execute-api.us-east-1.amazonaws.com/dev/invalidate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ env }),
      });
      const data = await response.json();
      toast({
        title: `Invalidate ${env}`,
        description: data.message, // Assuming the API returns a message
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.log(`Error invalidating ${env}:`, error);
      toast({
        title: `Error invalidating ${env}`,
        description: `There was an error invalidating the ${env} environment.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEmailInput(''); // Clear the email input
  };

  const handleClosePersonifyModal = () => {
    setIsPersonifyModalOpen(false);
    setFirstName('');
    setLastName('');
    setPersonifyEmail('');
  };

  const handleSubmitPersonify = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const email = user.signInUserSession.idToken.payload.email;
      // if (email !== 'evan.moses@daffodilhealth.co' || email !== 'adrienne.cromwell@daffodilhealth.co') {
      //   toast({
      //     title: `Permission Denied!`,
      //     description: 'You are not in the circle of trust!',
      //     status: 'error',
      //     duration: 5000,
      //     isClosable: true,
      //   });
      //   return;
      // }
      const response = await fetch('https://hvbhn2kc13.execute-api.us-east-1.amazonaws.com/dev/users/phase_one', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email: personifyEmail,
        }),
      });
      const data = await response.json();
      toast({
        title: 'User added',
        description: data.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      handleClosePersonifyModal(); // Close the modal after successful submission
    } catch (error) {
      console.log('Error adding user:', error);
      toast({
        title: 'Error adding user',
        description: 'There was an error adding the user.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchUsers = async (direction = null) => {
    try {
      let url = 'https://hvbhn2kc13.execute-api.us-east-1.amazonaws.com/dev/users';
      if (direction === 'next' && paginationToken) {
        url += `?next=${encodeURIComponent(paginationToken)}`;
      } else if (direction === 'prev' && prevPaginationToken) {
        url += `?prev=${encodeURIComponent(prevPaginationToken)}`;
      }

      const response = await fetch(url);
      const data = await response.json();

      if (data.prev) {
        data.users.reverse();
      }

      setUsers(data.users);
      setPaginationToken(data.users.length ? data.users[data.users.length - 1].pk : null);
      setPrevPaginationToken(data.users.length ? data.users[0].pk : null);
    } catch (error) {
      console.log('Error fetching users:', error);
      toast({
        title: "Error fetching users",
        description: "There was an error fetching the users.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteUser = async (email) => {
    try {
      const response = await fetch(`https://hvbhn2kc13.execute-api.us-east-1.amazonaws.com/dev/users/?email=${encodeURIComponent(email)}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        toast({
          title: "User access disabled",
          description: `Access for ${email} has been disabled.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // Refresh user list
        fetchUsers();
      } else {
        throw new Error('Failed to disable user');
      }
    } catch (error) {
      console.log('Error disabling user:', error);
      toast({
        title: "Error deleting user",
        description: `There was an error disabling the user: ${email}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Router>
      <Box p={4}>
        <Flex align="center" justify="space-between" bg="white.500" p={2} mb={4}>
          <Heading as="h1" color="bronze" size="xl" textAlign="left">
            Internal Tool
          </Heading>
          {isAuthenticated ? (
            <Flex alignItems="center">
              <Box mr={4}>
                <span>Welcome {userEmail}</span>
              </Box>
              <Button colorScheme="red" onClick={handleSignOut}>
                Sign Out
              </Button>
            </Flex>
          ) : (
            <Button colorScheme="green" onClick={handleSignIn}>
              Sign In
            </Button>
          )}
        </Flex>
        {isAuthenticated && (
          <Box mb={4}>
            <Stack direction="row" spacing={4} mb={2}>
              <Button colorScheme="blue" onClick={() => handleInvalidate('new_dev')}>
                Invalidate New Dev
              </Button>
              <Button colorScheme="yellow" onClick={() => handleInvalidate('temp_prod')}>
                Invalidate Temp Prod
              </Button>
              <Button colorScheme="red" onClick={() => handleInvalidate('prod')}>
                Invalidate Prod
              </Button>
            </Stack>
            <Stack direction="row" spacing={4} mb={2}>
              <Button colorScheme="teal" mb={4} onClick={() => setIsPersonifyModalOpen(true)}>
                Add Personify User
              </Button>
            </Stack>
            <Input
              type="email"
              placeholder="Enter email address to approve"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              mb={2}
            />
            <Button colorScheme="blue" onClick={handleSubmitEmail}>
              Submit
            </Button>
          </Box>
        )}
        {isAuthenticated && (
          <Box>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Email</Th>
                  <Th>Has access since</Th>
                </Tr>
              </Thead>
              <Tbody>
                {users.map((user) => (
                  <Tr key={user.pk}>
                    <Td>{user.pk}</Td>
                    <Td>{moment.unix(user.created_at).format('MM/DD/YYYY')}</Td>
                    <Td>
                      <Button colorScheme="red" onClick={() => handleDeleteUser(user.pk)}>
                        Disable Access
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Flex justify="space-between" mt={4}>
              <Button onClick={() => fetchUsers('prev')} disabled={!prevPaginationToken}>
                Previous
              </Button>
              <Button onClick={() => fetchUsers('next')} disabled={!paginationToken}>
                Next
              </Button>
            </Flex>
          </Box>
        )}
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Response Message</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{apiResponse}</ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" onClick={handleCloseModal}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={isPersonifyModalOpen} onClose={handleClosePersonifyModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Personify User</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                mb={3}
              />
              <Input
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                mb={3}
              />
              <Input
                placeholder="Email"
                value={personifyEmail}
                onChange={(e) => setPersonifyEmail(e.target.value)}
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleSubmitPersonify}>
                Submit
              </Button>
              <Button variant="ghost" onClick={handleClosePersonifyModal}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Router>
  );
};

export default App;
